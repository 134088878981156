import { BenefitDisplay, BenefitDisplayCard } from "../../view/components/BenefitsPanel/BenefitsDisplay";
import { PROGRESS_STATUS_OPTIONS } from "../../../../../constants";
import { AvatarContent } from "../../../../../components/ui/AvatarContent";
import { observer } from "mobx-react";
import { Panel } from "../../../../../components/ui/Panel";
import { UiSizes } from "@flightpath/coreui/dist/enums";
import I18n from "../../../../../core/localization/I18n";
import { useEffect, useState } from "react";
import { ProjectBenefitsPanelModel } from "./ProjectBenefitsPanel_model";
import { useCurrentOrganisationId } from "../../../../../services/local/organisationContext/OrganisationContextModel";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { PositionedSpinner } from "../../../../../components/ui/PositionedSpinner";
import Pages from "../../../../../routes/InsightRoutes";
import { Enums } from "../../../../../enums";
import { ProgressStatus } from "../../../../../components/ui/ProgressStatus";
import { RagStatus } from "../../../../../components/ui/RagStatus";

export const ProjectBenefitsPanel: React.FC<any> = observer(() => {
  const organisationId = useCurrentOrganisationId();
  const { projectId } = useParams<{ projectId: string; organisationId: string }>();
  const [model] = useState(() => new ProjectBenefitsPanelModel(organisationId, +projectId));
  const navigate = useNavigate();

  useEffect(() => {
    model.onMount();
    // eslint-disable-next-line
  }, []);

  if (model.benefitsData.length === 0)
    return (
      <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="p-3 mb-5">
        {model.isLoading && <PositionedSpinner />}
        {!model.isLoading && model.benefitsData.length === 0 && (
          <p className="mb-0">No benefits recorded in this project</p>
        )}
      </Panel.Panel>
    );

  return (
    <Panel.Panel hasBorder={true} background={Panel.PanelBackgrounds.BG_LIGHT} hasBorderRadius className="p-3 mb-5">
      <BenefitDisplay className={"row"}>
        {model.benefitsData.map(benefit => {
          const mapStatus = PROGRESS_STATUS_OPTIONS.find(progress => progress.key === benefit.progressStatus);
          const status = mapStatus ? I18n.t(mapStatus.label) : "N/A";
          return (
            <BenefitDisplayCard
              rag={
                benefit.progressStatus === Enums.ProgressStatus.COMPLETED ? (
                  <ProgressStatus state={benefit.progressStatus} />
                ) : (
                  <RagStatus state={benefit.ragStatus} />
                )
              }
              img={
                <AvatarContent
                  className="justify-content-end m-0"
                  avatarProps={{
                    size: UiSizes.MD,
                    imgSrc: benefit.ownerPicUrl
                  }}
                />
              }
              date={benefit.endDate === null ? "N/A" : moment(benefit.endDate).format("L")}
              title={benefit.name}
              value={benefit.value}
              status={status}
              navToExtendedView={() =>
                navigate(Pages.projects.benefits.extendedView.generateLink(organisationId, projectId, benefit.id))
              }
            />
          );
        })}
      </BenefitDisplay>
    </Panel.Panel>
  );
});
